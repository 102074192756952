/* External dependencies */
import { Col, Row, Text } from 'atomize';
import { navigate } from 'gatsby-plugin-react-intl';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'gatsby-plugin-react-intl';

/* Local dependencies */
import formatMessage from '../../intl/formatMessage';
import { RootState } from '../../redux/store';
import PrimaryButton from '../buttons/PrimaryButton';
import roles from '../common/roles';
import DropdownPicker from '../custom-dropdown/DropdownPicker';
import FormField from '../form/FormField';
import { rowStyle } from '../form/FormStyles';
import ErrorPopup from '../popup/ErrorPopup';
import PrimaryPopup from '../popup/PrimaryPopup';
import SuccessPopup from '../popup/SuccessPopup';
import {
  createUserRequest,
  resetCreateUser,
  resetError,
  updateUserFields,
} from './redux/actions';

export default function CreateUserForm() {
  const intl = useIntl();
  const borderColor = 'search_bg_color';
  const dispatch = useDispatch();
  const {
    error,
    firstNameError,
    lastNameError,
    loading,
    phoneError,
    roleError,
    user,
  } = useSelector((state: RootState) => state.newUser);

  const [isConfirmationPopupVisible, setConfirmationState] = useState(false);

  function onInputChange(e) {
    const { name, value } = e.target;

    dispatch(updateUserFields({ [name]: value }));
  }

  const cancel = intl.formatMessage({ id: 'cancel' });
  const add = intl.formatMessage({ id: 'add' });
  const addUser = intl.formatMessage({ id: 'addUser' });
  const addUserQuestion = intl.formatMessage({ id: 'addUserQuestion' });
  const userRole = user.role && intl.formatMessage({ id: user.role });
  const close = intl.formatMessage({ id: 'close' });
  const userAdded = intl.formatMessage({ id: 'userAdded' });
  const passwordSentBySMS = intl.formatMessage({ id: 'passwordSentBySMS' });
  const errorOccurred = intl.formatMessage({ id: 'errorOccurred' });
  const errorMessage = error && intl.formatMessage({ id: error.code });
  const userRoleLabel = intl.formatMessage({ id: 'userRole' });

  const isInvalid =
    firstNameError ||
    lastNameError ||
    phoneError ||
    roleError ||
    !user.firstName ||
    !user.lastName ||
    !user.phone ||
    !user.role;

  function openConfirmationPopup(e) {
    e.preventDefault();
    setConfirmationState(true);
  }

  function closeConfirmationPopup() {
    setConfirmationState(false);
  }

  function onSubmit() {
    dispatch(createUserRequest(user));

    setConfirmationState(false);
  }

  return (
    <form onSubmit={openConfirmationPopup}>
      <Row {...rowStyle} align="baseline">
        <FormField
          colSize="7"
          error={firstNameError}
          label={formatMessage('firstName')}
          inputValue={user && user.firstName}
          inputType="text"
          inputName="firstName"
          placeholder={formatMessage('usersFormFirstNamePlaceholder')}
          onChange={onInputChange}
          required={true}
          tabIndex="1"
        />
      </Row>
      <Row
        {...rowStyle}
        align="baseline"
        border={{ b: '1px solid' }}
        p={{ b: '1rem' }}
        borderColor="#EDF1F7"
      >
        <FormField
          colSize="7"
          error={lastNameError}
          label={formatMessage('lastName')}
          inputValue={user && user.lastName}
          inputType="text"
          inputName="lastName"
          placeholder={formatMessage('usersFormLastNamePlaceholder')}
          onChange={onInputChange}
          required={true}
          tabIndex="2"
        />
      </Row>
      <Row
        {...rowStyle}
        border={{ b: '1px solid' }}
        borderColor={borderColor}
        p={{ b: '1rem' }}
        flexWrap={{ xs: 'wrap', sm: 'nowrap' }}
      >
        <DropdownPicker
          inputName="role"
          items={roles}
          label={userRoleLabel}
          labelSize={{ xs: '12', sm: '5' }}
          onChange={onInputChange}
          text={'chooseRole'}
          value={user.role}
        />
      </Row>
      <Row {...rowStyle} align="baseline" p={{ b: '2rem' }}>
        <FormField
          colSize="7"
          error={phoneError}
          label={formatMessage('phone')}
          inputValue={user && user?.phone}
          inputType="tel"
          inputName="phone"
          placeholder={formatMessage('usersFormPhonePlaceholder')}
          onChange={onInputChange}
          required={true}
          fieldDescription={formatMessage('sentSMSCode')}
          tabIndex="4"
        />
      </Row>
      <PrimaryButton
        icon="Checked"
        size="lg"
        disabled={isInvalid || loading}
        loading={loading}
      >
        {addUser}
      </PrimaryButton>
      {isConfirmationPopupVisible && (
        <PrimaryPopup
          title={addUserQuestion}
          cancelBtnText={cancel}
          onClose={closeConfirmationPopup}
          btnText={add}
          onClick={onSubmit}
        >
          <Row>
            <Col>
              <Text>
                {userRole} {`-`} {user.firstName} {user.lastName}
              </Text>
            </Col>
          </Row>
        </PrimaryPopup>
      )}
      {user && user.id && !isInvalid && (
        <SuccessPopup
          onSubmit={() => {
            navigate('/users');
            dispatch(resetCreateUser());
          }}
          submitText={close}
          title={userAdded}
        >
          {passwordSentBySMS} {user && user.phone}
        </SuccessPopup>
      )}
      {error && (
        <ErrorPopup
          onSubmit={() => dispatch(resetError())}
          submitText={close}
          title={errorOccurred}
        >
          {errorMessage}
        </ErrorPopup>
      )}
    </form>
  );
}
