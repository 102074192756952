/* External dependencies */
import { Col, Container, Row, Text } from 'atomize';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';

/* Local dependencies */
import CreateUserForm from '../../../components/create-user';
import { resetCreateUser } from '../../../components/create-user/redux/actions';
import Layout from '../../../components/layout/';
import BackNav from '../../../components/nav/BackNav';
import DangerPopup from '../../../components/popup/DangerPopup';
import formatMessage from '../../../intl/formatMessage';
import { RootState } from '../../../redux/store';

export default function CreateUserPage() {
  const title = formatMessage('newUser');
  const {
    site: {
      siteMetadata: { titleTemplate },
    },
  } = useStaticQuery(query);

  const [showExitconfirmation, setConfirmationState] = useState(false);

  const { user } = useSelector((state: RootState) => state.newUser);

  const dispatch = useDispatch();

  const isExistFilledUserDetail =
    Object.entries(user).filter(([k, v]) => !!v).length >= 1;

  const onClickBackNav = () => {
    dispatch(resetCreateUser());
    isExistFilledUserDetail ? setConfirmationState(true) : navigate('/users');
  };

  return (
    <Layout>
      <Helmet>
        <title>{titleTemplate.replace('%s', title)}</title>
      </Helmet>
      <Container>
        <Row>
          <Col size={{ xs: '12', lg: '6' }} maxW="600px" w="100%">
            <BackNav
              label={formatMessage('newUser')}
              onClick={onClickBackNav}
            />
            <CreateUserForm />
          </Col>
        </Row>
        {showExitconfirmation && (
          <DangerPopup
            cancelText={formatMessage('cancel')}
            onClose={() => setConfirmationState(false)}
            onSubmit={() => navigate('/users')}
            submitText={formatMessage('exit')}
            title={formatMessage('newUserExitTitle')}
          >
            <Text>{formatMessage('newUserExitDescription')} </Text>
          </DangerPopup>
        )}
      </Container>
    </Layout>
  );
}

const query = graphql`
  query {
    site {
      siteMetadata {
        titleTemplate
      }
    }
  }
`;
