/* External dependencies */
import React from 'react';

/* Local dependencies */
import DangerButton from '../buttons/DangerButton';
import CancelButton from '../buttons/CancelButton';
import Popup from './Popup';
import PopupActions from './PopupActions';
import PopupContent from './PopupContent';

export default function DangerPopup({
  cancelText,
  children,
  disabled = false,
  onClose,
  onSubmit,
  minWidth = '',
  submitText,
  title,
}) {
  return (
    <Popup title={title} minWidth={minWidth}>
      <PopupContent>{children}</PopupContent>
      <PopupActions>
        <CancelButton onClick={onClose} textColor="text_color">
          {cancelText}
        </CancelButton>
        <DangerButton disabled={disabled} onClick={onSubmit}>
          {submitText}
        </DangerButton>
      </PopupActions>
    </Popup>
  );
}
